import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { LandingPageContainer } from "../../components/LandingPage/LandingPageContainer";

export const LandingPage = () => {
  return (
    <ChakraProvider>
      <LandingPageContainer />
    </ChakraProvider>
  );
};
