import React, { useState } from "react";
import {
  Button,
  Flex,
  Input,
  Text,
  Checkbox,
  FormControl,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import Navbar from "../../components/Navbar";
import Footer from "../Footer";
import axios from "axios";

export const LandingPageContainer = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [validatedEmail, setValidatedEmail] = useState("");
  const [consent, setConsent] = useState(false);
  const [invalidEmailError, setInvalidEmailError] = useState(true);
  const [error, setError] = useState("");

  const toast = useToast();

  const handleCheckBoxChange = (e) => {
    setConsent(e.target.checked);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleEmailChange = (e) => {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;

    setEmail(e.target.value);
    if (re.test(email)) {
      setValidatedEmail(e.target.value);
      setInvalidEmailError(false);
      setError("");
    } else {
      // invalid email, maybe show an error to the user.
      setInvalidEmailError(true);
      setError("Ugyldig epost");
    }
  };

  const regex = new RegExp("[^A-Za-zæøå\\s]");
  const hasIlligalCharacters = regex.test(name);
  const nameInputError = name.length > 50 || hasIlligalCharacters;
  const emailTooLongError = email.length > 50;

  const handleSubmitEmailCredentialsClick = async () => {
    setEmail("");
    setName("");
    setValidatedEmail("");
    setConsent(false);

    toast({
      title: "Takk for at du registrerte deg!",
      description:
        "En epost med link til nedlasting av dokumentet blir sendt innen kort tid.",
      status: "success",
      duration: 10000,
      isClosable: true,
    });

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(
      `https://snazzy-rabanadas-9edeea.netlify.app/.netlify/functions/post-email?name=${name}&email=${validatedEmail}`,
      requestOptions
    );
    const data = await response.json();
    console.log("data from LAMBDA function call:", data);
  };

  const contactForm = (
    <Flex flexDir={["column"]} justifyContent="center" mx={"auto"} px={5}>
      <FormControl isInvalid={nameInputError}>
        <Input
          value={name}
          onChange={handleNameChange}
          placeholder="Navn"
          my={3}
          bg="#FFF"
          height={["50px"]}
        />

        {nameInputError && (
          <FormErrorMessage style={{ marginBottom: "10px", marginTop: 0 }}>
            Ugyldig navn. Kun a-å. Max 50 tegn.
          </FormErrorMessage>
        )}
      </FormControl>
      <FormControl
        isInvalid={(emailTooLongError, invalidEmailError)}
        isRequired
      >
        <Input
          value={email}
          onChange={handleEmailChange}
          type={"email"}
          placeholder="Epost"
          mb={3}
          bg="#FFF"
          height={["50px"]}
        />
        {emailTooLongError && (
          <FormErrorMessage style={{ marginBottom: "10px", marginTop: 0 }}>
            Epost er for lang.
          </FormErrorMessage>
        )}
        {invalidEmailError && (
          <FormErrorMessage style={{ marginBottom: "10px", marginTop: 0 }}>
            {error}
          </FormErrorMessage>
        )}
      </FormControl>

      <Flex>
        <Checkbox
          onChange={handleCheckBoxChange}
          value={consent}
          mt="4px"
          bg="#fff"
          size="lg"
          style={{ height: "20px", width: "20px" }}
        />
        <Flex flexDir={"column"}>
          <Flex>
            <Text ml={3}>
              Ja, jeg ønsker å motta epost om nye kurs, tilbud og bonuser.
            </Text>
          </Flex>
          <Flex>
            <Text ml={3}> Du kan avslutte denne tjenesten når som helst.</Text>
          </Flex>
        </Flex>
      </Flex>
      <FormControl isInvalid={emailTooLongError}>
        <Button
          disabled={
            !consent ||
            name.length < 2 ||
            emailTooLongError ||
            nameInputError ||
            invalidEmailError ||
            validatedEmail.length == 0
          }
          colorScheme="blue"
          variant="solid"
          height={["50px"]}
          my={3}
          width="100%"
          onClick={handleSubmitEmailCredentialsClick}
        >
          Send meg listen nå!
        </Button>
      </FormControl>
      <Text>
        Informasjonen din er 100% trygg og vil aldri bli delt med noen andre.
      </Text>
    </Flex>
  );

  const contactFormInfo = (
    <Flex
      flexDir={["column"]}
      maxWidth={["auto", "450px"]}
      justifyContent="center"
      mx={"auto"}
      p={[3]}
    >
      <Text fontSize={["2xl"]} fontWeight={[600]}>
        Last ned planen for mentorprogrammet + liste med 20 ting en junior
        utvikler MÅ vite om - 100% gratis
      </Text>
      <Text>
        Med mentorprammet har du en klar plan over 5 måneder, fra scratch til
        utviklerjobb.
      </Text>
      <Text>
        Listen med 20 tips vil forberede deg på det viktigste når du har blitt
        en junior utvikler. Kombinert er dette alt du trenger for å få din
        første utviklerjobb OG mestre jobben når du først har fått den.
      </Text>
      <Text fontSize={["2xl"]} fontWeight={[600]} mt={3} color="#11cf4a">
        BONUS:
      </Text>
      <ul>
        <Text>
          <b>-</b> Liste med 20 tips alle junior utviklere MÅ vite om
        </Text>
      </ul>
    </Flex>
  );

  const infoTextContainer = (
    <Flex
      flexDir={["column"]}
      maxWidth={["100%", "460px"]}
      minWidth={["320px"]}
      mx={2}
    >
      <Text fontSize={["2xl", "3xl"]} fontWeight={[600]}>
        Hva inneholder den og hva kan du forvente?
      </Text>

      <Text fontSize={["md"]} mt={3}>
        Dette er en lett fordøyelige versjonen av mentorpgrammet slik at du kan
        få en smakebit av hva BliUtvikler kan tilby. Det har akkurat det samme
        innholdet som det betalte mentorpgrammet, bare i et enklere-å-lese
        format.
        <span style={{ fontWeight: 600 }}>
          {" "}
          Men hvorfor er det da gratis hvis det er det samme?
        </span>{" "}
        Alex Hormozi har en quote som oppsummerer det jeg prøver å gjøre på en
        god måte:{" "}
        <span style={{ fontStyle: "italic" }}>
          {" "}
          "Give away the secrets, sell the implementation".
        </span>{" "}
        Jeg gir deg <span style={{ fontWeight: 600 }}>
          {" "}
          alle hemmlighetene
        </span>{" "}
        om bransjen og alt jeg har av kunnskap og erfaringer gratis i håp om at
        du øker sjansene for å få jobben du vil ha. Og hvis du ønsker hjelp til
        å tilpasse det du har lært til din spesifikke situasjon og gå dypere ned
        i modulene, så har den betalte versjonen av mentorprammet ukentlig 1 til
        1 coaching over 5 måneder. Du kan lese mer om mentorprammet{" "}
        <a
          target="_blank"
          style={{
            fontWeight: 600,
            color: "blue",
            textDecoration: "underline",
          }}
          href="https://bliutvikler.no/"
        >
          her.
        </a>
      </Text>
      <Text fontSize={["md"]} mt={3}>
        Det du kan forvente er en plan basert på alle mine beste tips og
        strategier for å lære programmering og søke jobb. Alt er basert på min
        egen erfaring og research som jeg har opparbeidet meg over 4 år som
        utvikler. Opp til nå har halvparten av kundene mine fått jobb som
        utviklere (2 av 4). Og med resten er jeg overbevist det bare er et
        spørsmål om tid før det skjer. Hvis de kan klare det så kan du og!
      </Text>
      {/*       <Text fontSize={["md"]} mt={3}>
        Programmet består av 10 moduler akkurat som det vanlige
        mentorprogrammet. Hver modul gir deg en oversiktlig vei fram til neste
        modul med klare oppgaver eller sammendrag. Når du har gått gjennom alt
        som er beregnet å ta 5 måneder, så har du kunnskapen og informasjonen
        som trengs for å få din første utviklerjobb.
      </Text> */}
      {/*       <Text fontSize={["3xl"]} fontWeight={[600]} mt={4}>
        Hvorfor er dette gratis?
      </Text> */}
      {/*       <ul>
        <Text fontSize={["lg"]} mt={3}>
          <b>-</b> Denne listen tar for som alle soft skills og hard skills,
          tips og potensielle fallgruver og hverdagsrutiner i jobben som junior
        </Text>
        <Text fontSize={["lg"]} mt={3}>
          <b>-</b> Som junior kan det være vanskelig å passe inn og forstå
          forventningene til deg. Denne listen gjør det veldig tydelig hva du må
          kunne og hvordan du kan forbedre deg på kort tid og lengre perspektiv
        </Text>
        <Text fontSize={["lg"]} mt={3}>
          <b>-</b> Du slipper å gjøre de samme feilene som jeg gjorde da jeg
          startet som utvikler. Jeg lærte mye av det, men der og da var det ikke
          spesielt gøy.
        </Text>
        <Text fontSize={["lg"]} mt={3}>
          <b>-</b> Det å være utvikler kan være stressende og spesielt som
          junior er det lett å sammenligne seg selv med andre. Lær hvordan du
          får et mindset som server deg og ikke stresser deg.
        </Text>
      </ul> */}
    </Flex>
  );

  const titleText = (
    <Flex
      width="100%"
      alignItems={["center"]}
      flexDir={["column"]}
      mb={5}
      mt={3}
      textAlign="center"
    >
      <Text fontSize={["3xl", "5xl"]} mx={2}>
        Få en lettlest versjon av mentorprogrammet helt gratis!
      </Text>
      <Text fontSize={["2xl"]} fontWeight={[300]} mx={2}>
        Alt du trenger for å finne veien til en juniorutvikler jobb - selv om du
        begynner helt fra scratch!
      </Text>
    </Flex>
  );

  return (
    <Flex className="wrapper" flexDir={["column"]} width="100%">
      <Navbar />
      {titleText}
      <Flex
        flexDir={["column", "row"]}
        justify={["center"]}
        width="100%"
        flexWrap={"wrap"}
        mb={20}
      >
        {infoTextContainer}
        <Flex
          flexDir={["column"]}
          bg="#fff"
          minWidth={["320px"]}
          my={[5]}
          mx={[0, 5]}
          /* maxHeight={["auto", "725px"]} */
          /* maxHeight={["auto"]} */
        >
          <Flex flexDir={["column"]} bg="#eee">
            {contactFormInfo}
            {contactForm}
          </Flex>
        </Flex>
      </Flex>
      <Footer />
    </Flex>
  );
};
