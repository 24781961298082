import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";

import testimonialImageAdem from "../assets/adem_promotion_bilde01.PNG";
import testimonialImageNourhat from "../assets/1664262800520.jpg";
import testimonialImageKumar from "../assets/1655210578538.jpg";
import testimonialImageKani from "../assets/1681976280436_kani_testimonial.jpg";
export const TestimonalContainer = () => {
  const ademContent =
    "Jeg har fått skikkelig god hjelp av han. Han hjelper en til en i video-samtale. Han lærte meg spesielt hvordan jeg kan skrive god søknad. Jeg ble imponert av hans anbefalinger. Også fikk jeg svar mine mange spørsmål som jeg lurte på når jeg søker på jobb.";

  const nourhatContent =
    "Jeg har lært hvordan å skrive en veldig god cv og søknad. Jeg har mer kontroll over hva som skal skje og hvordan bør jeg svare på forskjellige spørsmål i 1 gangsintervjuet. Jeg vet hva slags type oppgaver jeg får i 2. gangsintervju og hvordan jeg bør forklare og løse. Før samarbeidet med Arne hadde jeg stress over hvordan jeg skal vise at jeg har kunnskap og kan løse problemstillingene jeg får presentert under intervjuet. Han veiledet meg til å redusere stresset og være forberedt på hvordan å løse på alternative måter. Jeg har lært mye om hva gjør en utvikler på hverdagen og hva er det som forventes av meg, viktigheten av å være selvstendig og sikker på arbeidet man gjør. Jeg vet nå veien til å bli flinkere utvikler";

  const kumarContent =
    "Veldig nyttig å ha en samtale med noen som har jobb innen koding. Jeg fikk veldig mye informasjon om hva man trenger for å få jobb, hvordan man søker jobb og hvordan jobbsøker prosessen er.";

  const kaniContent =
    "Arne har hjulpet meg med å skrive veldig gode søknader. Han har et veldig bra rammeverk for å bygge opp søknaden så du både svarer godt på utlysningen, men også solgt deg selv og klarer å skille deg ut blant kandidatene. Vi hadde to videosamtaler med et par ukers mellomrom og I løpet av denne perioden ble jeg kalt inn til flere intervjuer intervjuer etter at jeg tok i bruk rammeverket. Et par uker etter det igjen så landet jeg første jobb som utvikler. Rammeverket, tankegangen og innblikket jeg fikk i hverdagen til en utvikler er noe jeg tar med meg videre og som jeg drar fordel av, allerede nå som ny i jobb, men også i fremtidige jobbsøkerprosesser. Kan varmt anbefale Arne og spesielt søknadsworkshopen til alle enten du ønsker å bli utvikler, er nyutdannet eller ønsker å løfte kvaliteten på søknadene dine.";

  const singleTestimonial = (profileImage, content, name) => {
    return (
      <Flex
        flexDirection={["column", "column", "column", "row", "row"]}
        bg="#fff"
        border={"2px solid #0066cc"}
        borderRadius={"30px"}
        px={"20px"}
        w="100%"
        mt={"20px"}
        alignItems={"center"}
      >
        <Flex
          mx={2}
          justifyContent={"center"}
          pt={4}
          pb={2}
          minW={["120px", "120px", "120px"]}
          maxW={["200px"]}
          flexGrow={[1, 1]}
        >
          <img
            src={profileImage}
            alt="profile-image"
            className={"tesimonialImage"}
          />
        </Flex>
        <Flex w={"auto"} flexDir={"column"} alignItems={"end"}>
          <Text
            fontSize={["16px", "16px", "20px", "20px"]}
            fontStyle="italic"
            fontWeight="400"
            as="b"
            color="#0B3558"
            lineHeight="1.2"
            py={[0, "20px", "30px"]}
            mt={[0, 0, 0, "30px"]}
            ml={"10px"}
            flexGrow={[1, 2]}
          >
            "{content}"
          </Text>
          <Text fontWeight="bold" color="gray.600" fontSize={"24px"}>
            {name}
          </Text>
        </Flex>
      </Flex>
    );
  };

  return (
    <Flex
      id="about"
      justify="center"
      pt={["40px"]}
      pb="40px"
      bg="#0066cc"
      w="100%"
    >
      <Box>
        <Flex
          flexDirection="column"
          justify="center"
          w={["auto", 440, 599, 900]}
          mx="5px"
        >
          <Text
            fontSize={["xl", "3xl", "5xl"]}
            fontWeight="bold"
            as="b"
            color="#fff"
            lineHeight="2.2"
          >
            Erfaringer med BliUtvikler
          </Text>
          <Text
            fontSize={["xl", "3xl", "5xl"]}
            fontWeight="400"
            as="b"
            color="#fff"
            lineHeight="1.2"
          >
            Adem fikk første jobb som utvikler etter bare 2 uker etter at vi
            begynte å jobbe sammen!
          </Text>
          {singleTestimonial(
            testimonialImageAdem,
            ademContent,
            "Adem - Utvikler"
          )}
          {singleTestimonial(
            testimonialImageNourhat,
            nourhatContent,
            "Nourhat - Student"
          )}
          {singleTestimonial(
            testimonialImageKumar,
            kumarContent,
            "Kumar - Student"
          )}
          {singleTestimonial(
            testimonialImageKani,
            kaniContent,
            "Kani - Utvikler"
          )}
        </Flex>
      </Box>
    </Flex>
  );
};
