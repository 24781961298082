import React, { useRef } from "react";
import "./App.css";
import { Box, ChakraProvider, Flex } from "@chakra-ui/react";
import Hero from "./components/Hero";
import Navbar from "./components/Navbar";
import FeatureContainer from "./components/FeatureContainer";
import PitchContainer from "./components/PitchContainer";
import PriceContainer from "./components/PriceContainer";
import Footer from "./components/Footer";
import ScrollButton from "./components/ScrollToTopButton";
import SecondPitch from "./components/SecondPitch";
import ContactContainer from "./components/ContactContainer";
import { TestimonalContainer } from "./components/TestimonalContainer";
import { OfferContainer } from "./components/OfferContainer";
import { PaymentContainer } from "./components/PaymentContainer";
import CvAndApplicationInfo from "./components/ProductMoreInfo/CvAndApplicationInfo";
import TechnicalInterviewInfo from "./components/ProductMoreInfo/TechnicalInterviewInfo";
import ProjectTutorialLib from "./components/ProductMoreInfo/ProjectTutorialLib";
import PracticalJobInfo from "./components/ProductMoreInfo/PracticalJobInfo";
import GetFreeResourceContainer from "./components/GetFreeResourceFromLandingPage/GetFreeResourceContainer";
import { ThreeMonthProgramContainer } from "./components/ThreeMonthProgram/ThreeMonthProgramContainer";

function App() {
  const paymentref = useRef(null);
  const cvref = useRef(null);
  const interviewref = useRef(null);
  const projectref = useRef(null);
  const jobref = useRef(null);
  const mentorref = useRef(null);

  const handleBuyNowClick = () => {
    paymentref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleCvAndApplicationInfoClick = () => {
    cvref.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleTechnicalInterviewInfoClick = () => {
    interviewref.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleProjectTutorialLibClick = () => {
    projectref.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handlePracticalJobInfoClick = () => {
    jobref.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleShowMoreMentorInfoClick = () => {
    mentorref.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <ChakraProvider>
      <div className="wrapper">
        <Navbar />
        <div className="gradientNav">
          <Hero handleShowMoreMentorInfoClick={handleShowMoreMentorInfoClick} />
          <GetFreeResourceContainer />
          <PitchContainer />
          <SecondPitch />
          <TestimonalContainer />
          {/*           <OfferContainer
            handleBuyNowClick={handleBuyNowClick}
            handleCvAndApplicationInfoClick={handleCvAndApplicationInfoClick}
            handleTechnicalInterviewInfoClick={
              handleTechnicalInterviewInfoClick
            }
            handleProjectTutorialLibClick={handleProjectTutorialLibClick}
            handlePracticalJobInfoClick={handlePracticalJobInfoClick}
          /> */}
          <ThreeMonthProgramContainer mentorref={mentorref} />
          {/* <CvAndApplicationInfo cvref={cvref} />
          <TechnicalInterviewInfo interviewref={interviewref} />
          <ProjectTutorialLib projectref={projectref} />
          <PracticalJobInfo jobref={jobref} /> */}
          {/* <PaymentContainer paymentref={paymentref} /> */}
          <ContactContainer />
          <Footer />
          <ScrollButton />
        </div>
      </div>
    </ChakraProvider>
  );
}

export default App;
