import { Button, Flex, Icon } from "@chakra-ui/react";
import React from "react";
import {
  AiFillYoutube,
  AiFillLinkedin,
  AiFillGithub,
  AiFillFacebook,
} from "react-icons/ai";

function SocialMediaIcons() {
  return (
    <Flex justify="end" mt="10px" width={[200, 350, 500, 800]}>
      <Button size={["md", "lg"]} m="0 5px" bg="#fff">
        <a
          rel="noreferrer"
          href="https://www.facebook.com/profile.php?id=100010352762401"
          target="_blank"
        >
          <Icon
            as={AiFillFacebook}
            opacity="0.8"
            w={12}
            h={12}
            color="#4267B2"
          />
        </a>
      </Button>
      <Button size={["md", "lg"]} m="0 5px" bg="#fff">
        <a
          href="https://www.linkedin.com/in/arne-n%C3%A6ss-bjerk%C3%A5s-2139aa133/"
          target="_blank"
        >
          <Icon
            as={AiFillLinkedin}
            opacity="0.8"
            w={12}
            h={12}
            color="#0072b1"
          />
        </a>
      </Button>
      <Button size={["md", "lg"]} m="0 5px" bg="#fff">
        <a
          rel="noreferrer"
          href="https://www.youtube.com/channel/UC8E3kNAhfKmdrl_yCC-cQTw"
          target="_blank"
        >
          <Icon
            as={AiFillYoutube}
            opacity="0.8"
            w={12}
            h={12}
            color="#FF0000"
          />
        </a>
      </Button>
      <Button size={["md", "lg"]} m="0 5px" bg="#fff">
        <a rel="noreferrer" href="https://github.com/Polkagris" target="_blank">
          <Icon as={AiFillGithub} opacity="0.8" w={12} h={12} />
        </a>
      </Button>
    </Flex>
  );
}

export default SocialMediaIcons;
