import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import SocialMediaIcons from "./SocialMediaIcons";

function Footer() {
  return (
    <Flex
      flexDir="column"
      bgGradient="linear(to-r, #6c6ce4, #63e5ff)"
      h="290px"
      w="100%"
      pt="20px"
      justify="space-between"
      minW="240px"
    >
      <Flex justify="start" wrap="wrap">
        <Flex flexDir="column" m="0 40px">
          <Box fontWeight="bold" color="#fff">
            Om nettsiden <span>🔍</span>
          </Box>
          <Box fontSize="11px" w="200px" color="#fff">
            Denne nettsiden er laget med React og Chakra Ui, og deployet hos
            Domene.shop
          </Box>
        </Flex>
        <Flex justify="space-around">
          <Flex flexDir="column" m="0 40px">
            <Box fontWeight="bold" color="#fff">
              Kontakt <span>📧</span>
            </Box>
            <Box fontSize="11px" w="200px" color="#fff">
              Email: post@bliutvikler.no
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <Flex w="400" ml={[0, "40px"]} flexDir="column">
        <SocialMediaIcons />
      </Flex>

      <Box fontSize="13px" ml="30px" fontWeight="400">
        All rights reserved | This website is made with love by Arne Næss
        Bjerkås | Bjerkås Coaching
      </Box>
    </Flex>
  );
}

export default Footer;
