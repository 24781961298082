import React from "react";
import { Button, Flex, Text } from "@chakra-ui/react";

function GetFreeResourceContainer() {
  return (
    <Flex mt={[1, 12]} w={"100%"} justifyContent={"center"}>
      <Flex w={["100%", "45%"]}>
        <Flex flexDir={["column"]} alignItems={"center"}>
          <Text fontSize={["2xl", "4xl"]} mx={3}>
            Vil du starte karrieren med en gang?
          </Text>
          <Flex flexDir={["row"]} justifyContent={"center"}>
            <Text fontWeight={400} mx={3}>
              {`Last ned en lettlest versjon av mentorprogrammet - en komplett plan til jobb fra scratch`}
              <span style={{ color: "#1fbf49", fontWeight: "700" }}>
                {" "}
                - helt gratis!
              </span>
            </Text>
          </Flex>

          <Flex width="100%" justifyContent={"center"} mt={[3]}>
            <a href="/gratis" target="_blank">
              <Button colorScheme="orange" size="lg" w={["250px"]}>
                Gi meg programmet!
              </Button>
            </a>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default GetFreeResourceContainer;
