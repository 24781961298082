import {
  Button,
  Flex,
  Icon,
  Text,
  useClipboard,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { AiFillFacebook } from "react-icons/ai";

function ClipboardButton() {
  const [value, setValue] = React.useState("post@bliutvikler.no");
  const { hasCopied, onCopy } = useClipboard(value);
  const toast = useToast();

  const handleCopyToClipboard = () => {
    onCopy();
    toast({
      title: "Epost kopiert til clipboard!",
      description: "post@bliutvikler.no",
      status: "success",
      duration: 4000,
      isClosable: true,
    });
  };
  return (
    <Flex flexDir={"column"} alignItems="center" mt="80px">
      <Text fontSize={["16px", "24px"]} w={["auto", 400, 600]} mx="5px">
        Vil du bli med i mentorprogrammet ta kontakt med meg i dag for en
        uformell prat så vi finner ut om det er en bra fit!
      </Text>
      <Flex alignItems={"center"}>
        <Flex>
          {" "}
          <Button
            onClick={handleCopyToClipboard}
            fontWeight="500"
            fontSize={["auto", "24px"]}
            m="20px 0"
            bgGradient="linear(to-r, #6c6ce4, #63e5ff)"
            p="30px"
            width={["170px", "300px"]}
          >
            post@bliutvikler.no
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default ClipboardButton;
