import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import promotionImage from "../../assets/promotering_bliuvikler_v8.PNG";

export const ThreeMonthProgramContainer = ({ mentorref }) => {
  return (
    <Flex
      flexDir={["column"]}
      bgColor={"#fff"}
      w={["100%"]}
      align={["center"]}
      pt={12}
    >
      <Flex flexDir={["column"]} w={["100%", "80%", "60%"]}>
        <Flex justify={["center"]}>
          <Text
            fontSize={["2xl", "6xl"]}
            fontWeight={["700"]}
            mx={[2]}
            my={["5rem"]}
            ref={mentorref}
          >
            Mentorprogrammet
          </Text>
        </Flex>
        <Flex
          flexDir={["column"]}
          fontSize={["md", "lg"]}
          fontWeight={["400"]}
          justify={["center"]}
          mx={[2]}
          mt={["16px"]}
        >
          <Flex>
            <Text my={[2]} fontSize={["lg", "3xl"]} fontWeight={["700"]}>
              Hvordan programmet ble til
            </Text>
          </Flex>
          <Flex mb={[2]}>
            <Text>
              Oppfølging av en erfaren utvikler over tid som bryr seg om min
              karriere og vil se meg lykkes. Det er det jeg ønsket meg da jeg
              begynte å sikte på utvikleryrket, uvitende om det meste av hva
              jobben egentlig innebar siden studiet jeg hadde gått ikke var
              rettet mot utvikling på tross av å være et IT studie
              (elektronikk).
            </Text>
          </Flex>

          <Flex mb={[2]}>
            <Text>
              På grunn av at jeg ikke visste hvilken vei jeg burde ta til enhver
              tid eller hvordan jeg kunne løse utfordringer underveis på en
              effektiv måte så tok det mye lengre tid enn det det trengte å ta.
              Jeg følte mye på følelsen av{" "}
              <span style={{ fontStyle: "italic" }}>
                håpløshet, stress og av å være overveldet konstant.{" "}
              </span>
              <span style={{ fontWeight: 700 }}>
                Veien til å bli utvikler for meg var{" "}
                <span style={{ fontStyle: "italic" }}>ikke</span> gøy.
              </span>
            </Text>
          </Flex>

          <Flex mb={[2]}>
            <Text>
              Ser jeg tilbake nå så var jeg på en måte veldig heldig for jeg
              hadde en venn som allerede var utvikler som jeg kunne spørre om
              råd underveis. Og det hjalp meg utrolig mye i forhold til det å få
              jobb og fokusere på riktig ting i forhold til programmering. Og
              etter at jeg endelig kom meg i jobb (jeg hadde mine tvil om at det
              i det hele tatt ville skje), så fikk jeg et slags aha-øyeblikk
              relatert til den selvlærte ruta til utvikleryrket som jeg på en
              måte føler jeg hadde vært gjennom.
            </Text>
          </Flex>

          <Flex mb={[2]}>
            <Text>
              Det må nevnes at ja, jeg hadde en bachelor i et studie som ga meg
              mye i forhold til det å kunne løse logiske problemer, og det ga
              meg en fordel i forhold til jobbsøking, men programmering i
              praksis hadde jeg svært lite erfaring med. Jeg hadde ett fag som
              var dediktert til C-programmering, litt PLS programmering (språk
              mye brukt i industri) og noen labber med Arduino, og thats it.
            </Text>
          </Flex>

          <Flex mb={[2]}>
            <Text>
              Med alt det jeg hadde av fordeler - litt erfaring med koding fra
              før, en slags mentor som hjalp meg underveis og en bachelor innen
              IT, så var det fortsatt så vanskelig å finne ut av alt, og ikke
              minst få en jobb. Jeg tenkte til slutt -{" "}
              <span style={{ fontWeight: 700 }}>
                det må da være en enklere måte å få selvlærte utviklere ut i
                jobb på?
              </span>{" "}
              Med det spørsmålet ble på en måte dette programmet til, selv om
              det har tatt flere år til å gjøre det til noe ekte. Jeg har fått
              bevis på at man kan lære seg alt man trenger for å få en junior
              jobb utenom skolen, som har gitt meg en sterk indre motivasjon til
              å lage et bedre alternativ for selvlærte. Nå vil jeg vise andre at
              det er mulig å gjøre det på en mye enklere og smidigere måte enn
              sånn jeg måtte gjøre det.
            </Text>
          </Flex>

          <Flex my={[2]}>
            <Text>
              Alle som ønsker oppfølging fra noen som faktisk har
              arbeidserfaring som utvikler burde kunne få tilgang til det, og
              det er det jeg føler meg kallet til å tilby etter det jeg selv var
              igjennom på veien til utviklerjobben, der jeg sårt kunne trengt
              litt hjelp og oppfølging. De fleste klarer å komme seg til en jobb
              på tross av lite oppfølgning, men{" "}
              <span style={{ fontWeight: 700 }}>til hvilken pris?</span> Og hvor
              mange flere kunne blitt utviklere, men falt av underveis pga for
              høyt press eller uklarheter i forhold til hva som kreves av
              kompetanse og hvordan man får jobb? Går man en universitetsgrad så
              blir man satt på et slags samlebånd som gradvis setter sammen det
              meste av kompetansen som trengs - men det tar minst 3 år! (for
              ikke å snakke om studielånet) Går man for en selvlært løsning så
              har man ingenting som guider deg på veien, du må finne ut av det
              selv. Det ga meg ideen til en mentor. Hvor fantastisk hadde det
              ikke vært om du ville lære programmering på egenhånd for å få
              jobb, også hadde du tilgang til en mentor med erfaring fra
              arbeidslivet som kunne hjelpe deg underveis? Det var det som slo
              meg etter jeg endelig fikk meg jobb.
            </Text>
          </Flex>
          <Flex my={[2]}>
            <Text>
              Jeg har tro på at oppfølging av noen med{" "}
              <span style={{ fontWeight: 700 }}>erfaring fra arbeidslivet</span>
              , og en klar plan for læring, gjør opp for mye av tiden som ellers
              ville blitt brukt på feks det å lære unødvendig mye teori og ting
              man skjeldent får bruk for, men må igjennom fordi det står i
              læreplanen. For ikke å snakke om jobbsøkerprosessen som på en måte
              blir tatt for gitt at du ordner på egenhånd. Derfor tror jeg at
              jeg har funnet
              <span style={{ fontStyle: "italic" }}>
                {" "}
                en formel på hvordan man kutter et utdanningsløp ned fra flere
                år til flere måneder og samtidig beholder kvaliteten, men også
                noe man ikke får noe annet sted enn i arbeidslivet.{" "}
              </span>{" "}
              <span style={{ fontWeight: 700 }}>
                Dette er en helt ny måte å bli utvikler på.
              </span>
            </Text>
          </Flex>

          <Flex>
            <Text
              my={[2]}
              fontSize={["lg", "3xl"]}
              fontWeight={["700"]}
              mt={12}
            >
              Litt om Arne
            </Text>
          </Flex>
          <Flex mb={[2]}>
            <Text>
              I 2018 ble jeg uteksaminert fra OsloMet med en bachelor i
              elektronikk og IT. Fra enda tidligere tilbake tok jeg en bachelor
              i japansk språk og kultur, der jeg fikk muligheten til å studere
              litt i Japan. Veldig givende og spennende, men lite aktuelt for
              jobber jeg var interessert i. Jeg fant ut at jeg ville bli
              utvikler kort tid <span style={{ fontStyle: "italic" }}>før</span>{" "}
              jeg ble ferdig med studiene og hadde ingen anelse om prosessen
              eller hva jeg måtte kunne. Ettersom det svært lite fokus på
              programmering på studiet mitt, så hadde jeg{" "}
              <span style={{ fontWeight: 700 }}>en lang vei å gå</span> i
              forhold til det å skaffe meg kompetansen jeg trengte.
            </Text>
          </Flex>
          <Flex my={[2]}>
            <Text>
              Et annet problem var at jeg ikke visste hvordan jeg kunne{" "}
              <span style={{ fontStyle: "italic" }}>bli</span> en utvikler -
              altså hvor kunne jeg lære det jeg trengte, og hvor mye trengte
              jeg? Hvor kunne jeg i det heletatt begynne? Jeg brukte en del tid
              på å forhøre meg med andre og søke rundt på nettet, og fant
              tilslutt gode gratis resurser som Freecodecamp og diverse
              kodekanaler på Youtube. Det var en lettelse å vite at
              informasjonen var tilgjengelig, men det var bare den enkle delen.
              Nå kom <span style={{ fontWeight: 700 }}>det vanskelige</span> -
              det å faktisk lære koding på et bortimot profesjonelt nivå. Jeg
              bestemte meg for å ta alt som var av grunnleggende frontend på
              Freecodecamp til jeg kom til React. Jeg skjønte at om jeg ville ha
              jobb innen frontend så måtte jeg kunne React. Det var det alle på
              nettet sa. Det tok bortimot{" "}
              <span style={{ fontWeight: 700 }}>4 måneder </span>til jeg begynte
              litt med React. Da hadde jeg vært innen mye fundamentale ting og
              følte meg klar. Problemet var at jeg hadde en tendens til å bytte
              ut det jeg lærte meg da det begynte å bli vanskelig, og det
              skjedde ganske ofte. Det gjorde at jeg ikke lærte ting i dybden.
              Jeg var redd for å utfordre meg selv, fordi det å feile føltes som
              nok et nederlag. Og jeg kunne snart ikke ta flere av dem, før jeg
              bare la drømmen på hylla og bestemte meg for å heller ta en
              "vanlig" jobb. Problemet med det er at det å feile i begynnelsen
              er helt naturlig og noe man må gjennom - mange ganger. Helt til
              man blir vant til ubehaget. Hver gang jeg kjente på{" "}
              <span style={{ fontWeight: 700 }}>ubehaget</span> av å ikke mestre
              det jeg holdt på med så fikk jeg impulsen til å hoppe over til et
              nytt spennende rammeverk som lovte meg at det skulle være det
              "next best thing" og mere verdt min tid enn det jeg holdt på med i
              øyeblikket. Dette resulterte i at min React kompetanse var ganske
              langt fra det nivået som kreves i en utviklerjobb. Et annet
              problemet her var at det visste ikke jeg. Jeg hadde ingenting å
              sammenligne det med på en måte jeg kunne forstå. Jeg skjønte jo at
              jeg ikke var noen ekspert, men hvor langt unna jeg var var helt
              uklart.
            </Text>
          </Flex>
          <Flex my={[2]}>
            <Text>
              Jeg har blitt ganske flink til å skrive etter en del år på
              universitet, så jeg lærte meg ganske raskt å skrive søknader som
              ga meg <span style={{ fontWeight: 700 }}>intervju</span>. I et
              teknisk intervju jeg klarte å komme meg til på tross av alt, ble
              det knusende klart at jeg ikke var god nok, der jeg slet med å
              forstå de fleste konseptene som jeg ble spurt om. Det var{" "}
              <span style={{ fontWeight: 700 }}>ekstremt tungt å innse</span>{" "}
              realiteten og drømmen om å bli utvikler hang i balansen. På et
              tidspunkt etter noen runder med lignende scenarioer vurderte jeg
              sterkt andre jobber, og søkte på alt fra techsupport til
              installatør av elektronisk helseutstyr på sykehus. Jeg skjønte
              etterhvert at jeg trengte å endre fokus, og bestemte meg for å
              bite tennene sammen, lære mer og fortsette å søke utviklerjobber.
              Det ga meg heldigvis gevinst.
            </Text>
          </Flex>

          <Flex my={[2]}>
            <Text>
              Etter omtrent <span style={{ fontWeight: 700 }}>et halvt år</span>{" "}
              etter at jeg ble uteksaminert fikk jeg jobb. Jeg hadde på
              mirakuløst vis klart å komme meg inn i Experis Academy i Oslo. Det
              bestod av en bootcamp på 3 måneder som stod for opplæringen, og
              videre konsulentjobb som Javautvikler i ett år. Bootcampen tok for
              seg veldig mye forskjellig, men hadde hovedfokus på Java, noe jeg
              ikke hadde vært i nærheten av. På det tidspunktet visste jeg
              egentlig ingenting om backend programmering, noe som skulle vise
              seg å gjøre ting ekstra utfordrende. Til tross for det kom jeg meg
              på en eller annen måte gjennom Bootcamp og opplæringsperiode.
              Mange intervjuer og{" "}
              <span style={{ fontWeight: 700 }}>tre konsulentoppdrag</span>{" "}
              senere så er jeg i dag fast ansatt i Buypass som fullstack
              utvikler og holder på med bliUtvikler som en sidejobb i håp om å
              hjelpe noen som befinner seg i lignende situasjon som jeg gjorde
              på jakt etter utviklerjobben.
            </Text>
          </Flex>

          <Flex my={[2]}>
            <Text fontWeight={["700"]} fontSize={["lg", "3xl"]} mt={12}>
              Hvem passer dette mentorprogrammet for?
            </Text>
          </Flex>
          <Flex my={[2]}>
            <Text>
              Mentorprogrammet er desinget for deg som{" "}
              <span style={{ fontWeight: 700 }}>
                ikke har noe kunnskap innen programmering
              </span>{" "}
              fra før, og vil starte en ny karriere som utvikler på en helt ny
              måte. Du har et sterkt ønske om å bli en junior utvikler{" "}
              <span style={{ fontStyle: "italic" }}>
                uten å gå flere år med skole
              </span>
              , men er motivert til å gjøre en innsats på tross av det. Du vil
              ha et tydelig løp fra start til slutt med regelmessig 1 til 1
              oppfølging av en <span style={{ fontWeight: 700 }}>mentor</span>{" "}
              som holder deg på rett kurs, bryr seg om din karriere, og coacher
              deg gjennom utfordringer. Du vil lære{" "}
              <span style={{ fontWeight: 700 }}>hvordan man søker</span> en
              utviklerjobb og faktisk får den. Høres ut som noe for deg? Ta
              kontakt på post@bliutvikler.no i dag.
            </Text>
          </Flex>

          <Flex my={[2]}>
            <Text fontWeight={["700"]} fontSize={["lg", "3xl"]} mt={12}>
              Hvordan er programmet bygd opp? Og hva lærer jeg?
            </Text>
          </Flex>
          <Flex my={[2]}>
            <Text>
              Programmet er bygd opp av totalt{" "}
              <span style={{ fontWeight: 700 }}>10 moduler</span> som går over 5
              måneder. Alle modulene har sin egen plan med oppgaver for
              selvlæring og informasjon dedikert til å løse hver oppgave - alt
              på ett sted. Hver modul innenfor kodedelen består av opptil 10
              mindre oppgaver og 2 til 3 større oppgaver som er så like som
              mulig egne{" "}
              <span style={{ fontWeight: 700 }}>
                oppgaver jeg har fått i jobbsammenheng
              </span>
              , og på den måten gir det deg så ekte arbeidserfaring som mulig.
              De 3 første månedene er satt av til kodedelen med ett
              masterprosjekt som du fortsetter å jobbe med ut løpet i parallell
              med andre moduler.
            </Text>
          </Flex>

          <Flex my={[2]}>
            <Text>
              Det er satt opp{" "}
              <span style={{ fontWeight: 700 }}>ukentlig konsultasjoner</span>{" "}
              over Zoom som er ment å holde deg på riktig kurs og få deg raskt i
              gang med hver modul. Det vil si at konsultasjonene utenom de som
              tar for seg modulene kan brukes til å gi deg tilbakemelding på
              oppgavene du har gjort og hjelpe deg underveis hvis noe er
              spesielt ufordrende der og da. De konsultasjoner som gjelder
              onboardingen i starten og gjennomgang av moduler vil vare lengre
              for å forsikre at du kommer i gang på en god måte, mens andre
              konsultasjoner kan bare være en rask status om det ikke er noe
              spesielt behov i øyeblikket. Vi ønsker at du skal{" "}
              <span style={{ fontWeight: 700 }}>bli en selvgåen utvikler</span>{" "}
              som vil si at du klarer å finne ut av ting på egenhånd, og er
              kanskje den viktigste egenskapen hos en junior. Det betyr
              selvfølgelig ikke at jeg slutter å hjelpe deg, men at det er
              bevisstgjøring rundt det å prøve ting selv før du ber om hjelp. Og
              det er alltid greit å ta opp ting på chat hvis du står fast med
              noe. Hvor lang tid man bruker på hver modul er individuelt så det
              er en grov inndeling, men hver modul er like viktig for helheten.
              Det dreier seg ikke kun om kode (selvom det er veldig viktig), men{" "}
              <span style={{ fontWeight: 700 }}>
                hvordan man blir en junior utvikler
              </span>{" "}
              og hva det innebærer.
            </Text>
          </Flex>

          <Flex my={[2]}>
            <Text>
              Personlig så har jeg en{" "}
              <span style={{ fontWeight: 700 }}>
                bakgrunn fra frontendverden
              </span>
              , så jeg har en overvekt med erfaring innenfor React i forhold til
              Java, selvom jeg også har arbeidserfaring med det, Spring boot og
              Kotlin. Derfor kan du forvente at det blir brukt{" "}
              <span style={{ fontWeight: 700 }}>mer tid på React</span> og
              frontend enn Java og spring boot, men nok til at du forstår godt
              hva en backend gjør og hvordan man setter opp et Rest API med CRUD
              funksjonalitet. Frontend og React er noe jeg jobber med hver dag
              som utvikler, og som jeg er opptatt av å kontinuerlig forbedre meg
              i, så jeg vet veldig godt hvor nivået på koden er forventet å
              være. Med det sagt så er det ikke noe i veien for å fokusere på
              backend og dedikere master prosjektet til det. Jeg vil bare
              presisere at det er lagt opp til å fokusere mer på frontend enn
              backend slik modulene er satt opp nå. Her er en oversikt over alle
              modulene og kort om hva de går ut på:
            </Text>
          </Flex>

          <Flex my={[2]}>
            <ul>
              <Text color="#0B3558" fontSize={["lg"]} mt={5}>
                <b>-</b> Del 1 - Frontend fundamentals og Git basics
              </Text>
              <Text color="#0B3558" fontSize={["lg"]} mt={5}>
                <b>-</b> Del 2 - Frontend og API
              </Text>
              <Text color="#0B3558" fontSize={["lg"]} mt={5}>
                <b>-</b> Del 3 - Frontend og React
              </Text>
              <Text color="#0B3558" fontSize={["lg"]} mt={5}>
                <b>-</b> Del 4 - Backend og Java
              </Text>
              <Text color="#0B3558" fontSize={["lg"]} mt={5}>
                <b>-</b> Del 5 - Master project
              </Text>
              <Text color="#0B3558" fontSize={["lg"]} mt={5}>
                <b>-</b> Del 6 - Jobbsøkerprosessen
              </Text>
              <Text color="#0B3558" fontSize={["lg"]} mt={5}>
                <b>-</b> Del 7 - CV og søknadsframework
              </Text>
              <Text color="#0B3558" fontSize={["lg"]} mt={5}>
                <b>-</b> Del 8 - Intervjutrening
              </Text>
              <Text color="#0B3558" fontSize={["lg"]} mt={5}>
                <b>-</b> Del 9 - Teknisk intervjutrening
              </Text>
              <Text color="#0B3558" fontSize={["lg"]} mt={5}>
                <b>-</b> Del 10 - Jobben som juniorutvikler i praksis
              </Text>
            </ul>
          </Flex>
          <Flex my={[2]}>
            <Text></Text>
          </Flex>

          <Flex my={[2]}>
            <Text fontWeight={["700"]} fontSize={["lg", "3xl"]} mt={12}>
              Hva kan du forvente å få ut av mentorprogrammet?
            </Text>
          </Flex>
          <Flex my={[2]}>
            <Text>
              Gitt at du har fulgt programmet i 5 måneder, gjort oppgavene
              tildelt til deg og fulgt råd og anbefalinger gitt av din mentor så
              kan du forvente å være klar til å søke junior stillinger. Det vil
              si at du har{" "}
              <span style={{ fontWeight: 700 }}>
                kompetansen som trengs av en junior utvikler
              </span>{" "}
              og vet hvordan man søker på junior utvikler stillinger på en
              effektiv måte som gir resultater. Det er ikke noen garantier for
              at du får jobb som utvikler selv om du har fullført programmet,
              men du vil ha alle verktøyene som trengs for å få en jobb på
              egenhånd og gjøre den jobben på en tilfredstillende måte. Du vil
              ha opparbeidet deg praktisk erfaring med koding gjennom flere og
              komplekse prosjekter som emulerer features til virkelige
              applikasjoner, som har resultert i ett master prosjekt som viser
              tydelig hvor du er faglig og som du kan vise potensielle
              arbeidsgivere. Du vet hvordan rutinene og hverdagen til en
              utvikler typisk ser ut og kan raskt tilpasse deg til en slik
              arbeidshverdag. Du har lært et{" "}
              <span style={{ fontWeight: 700 }}>
                framework for å skrive søknader
              </span>{" "}
              som genererer skreddersydde søknader og viser kompetanse og
              interesse på en måte som blir lagt merke til. Du har lært hvordan
              <span style={{ fontWeight: 700 }}>
                {" "}
                rekrutteringsprosessen
              </span>{" "}
              fungerer og hva du kan forvente og på den måten være bedre
              forberedt. Du kan gi et{" "}
              <span style={{ fontWeight: 700 }}>
                godt intrykk på intervju
              </span>{" "}
              etter flere runder med øving, tips og tilbakemeldinger og har
              opparbeidet deg praktisk erfaring med den tekniske intervjudelen
              med tavleoppgaver og faglig diskusjon. Gjennom de totalt 5
              månedene har du blitt eksponert for det viktigste en junior
              utvikler må kunne relatert det kodekompetanse, hvordan jobben
              fungerer og jobbsøking, og du er nå klar for din første jobb som
              utvikler.
            </Text>
          </Flex>

          <Flex my={[2]}>
            <Text fontWeight={["700"]} fontSize={["lg", "3xl"]} mt={12}>
              Hvorfor ta mentorprogrammet?
            </Text>
          </Flex>

          <Flex my={[2]}>
            <Text>
              Det er{" "}
              <span style={{ fontWeight: 700 }}>3 forskjellige måter</span> å
              bli utvikler på. Du kan gå{" "}
              <span style={{ fontWeight: 700 }}>det formelle løpet</span> på
              universitetet, høyskole eller fagskole i 3-5 år og ta en bachelor
              eller en master. Det er det tradisjonelle løpet og står for
              mesteparten av utviklerne i dag vil jeg tippe.{" "}
            </Text>
          </Flex>

          <Flex my={[2]}>
            <Text>
              <span style={{ fontWeight: 700 }}>Alternativ 2</span> er å ta en{" "}
              <span style={{ fontWeight: 700 }}>bootcamp</span>, som har vært i
              vinden en stund og tar deg gjennom et aksellerert opplæringsløp
              over 2-5 måneder typisk. Det som har blitt populært er at
              bedrifter rekrutterer lovende kandidater som de tar gjennom en
              slik bootcamp for å så bruke dem som konsulenter etter de er
              ferdig med opplæringen. Jeg gikk nettopp et slikt opplegg. Dette
              er i andre ord ikke tilgjengelig for alle som ønsker det siden
              antall søkere er mange og ledige plasser er ganke få (rundt 30 per
              kull). Ønsker du noe du kan bare kjøpe deg tilgang til så må du
              selvfølgelig betale alt selv og det er ikke noen garantier for
              jobb etterpå, noe du må fikse helt på egenhånd. Prisen er som
              regel ca 50.000-80.000 kr for et treningsprogram på 8-16 uker der
              du blir en av 20-30 elever i en "klasse". Man ser ikke så mange
              slike bootcamps lengre i Norge.{" "}
            </Text>
          </Flex>
          <Flex my={[2]}>
            <Text>
              <span style={{ fontWeight: 700 }}>Det siste alternativet</span> du
              har for å bli utvikler, som er ganske unikt for en teknisk jobb
              med relativt høy lønn er at du kan{" "}
              <span style={{ fontWeight: 700 }}>lære alt selv</span>. Så lenge
              du har kompetansen de ser etter på arbeidsplassen du søker så kan
              du få jobben. Ikke alle arbeidsgivere aksepterer kandidater fra en
              slik bakgrunn, men mange nok gjør det. Til og med statlige
              institusjoner i Norge skriver i jobbannonsene sine at erfaring kan
              gjøre opp for formell utdannelse. Dette er løsningen for de med
              disiplin og motivasjon. Det kan være veldig krevende å lære alt
              selv, men det er mulig. Har du noen år med erfaring så åpner mange
              dører seg. Og de dørene kan føles veldig lukket hvis du ikke har
              det.
            </Text>
          </Flex>
          <Flex my={[2]}>
            <Text>
              Derfor vil jeg tilby et helt nytt{" "}
              <span style={{ fontWeight: 700 }}>fjerde alternativ</span>, som
              tar deg gjennom alt du trenger for å få jobb på 5 måneder, og
              sørger for at du får erfaring med kode gjennom prosjekter som er
              så nære det som blir gjort i arbeidslivet at man ikke kan
              argumentere for at det er noen forskjell. I tillegg til det vil
              jeg vise hvordan hele jobbsøkerprosessen er for en utvikler og
              hvordan du faktisk får jobb som junior selv uten erfaring eller
              formell utdannelse. Det er noe du typisk må lære selv mens du
              faktisk søker jobber. Det er ihvertfall ingen som lærer deg
              hvordan du søker spesifikt på utviklerjobber såvidt jeg vet. Du
              vil også ha ukentlige konsultasjoner med en utvikler som vil
              fungere som din{" "}
              <span style={{ fontWeight: 700 }}>personlige mentor</span> og som
              passer på at du alltid er på riktig kurs. Det får du ikke noe
              annet sted enn i arbeidslivet og gjør dette alternativet helt
              unikt.
            </Text>
          </Flex>

          <Flex my={[2]}>
            <Text>
              Hvor mye koster et semester på en fagskole der du lærer frontend
              over 2 år?{" "}
              <span style={{ fontWeight: 700 }}>
                Over 40.000 kr for nettstudier
              </span>
              . Over 60.000 hvis du vil ha undervisning på skolen. Studielånet
              på en bachelor eller master er heller ikke å kimpse av (300.000 kr
              og oppover).{" "}
              <span style={{ fontStyle: "italic" }}>
                Hvor mye personlig oppfølging tror du du får på tross av den
                høye summen du har betalt (eller skal betale i form av
                studielån)?
              </span>{" "}
              Jeg vil tro ganske minimalt. Du får nok tilbakemeldinger og
              karakterer på innleveringer og prosjekter, og en epost i ny og ned
              som gir deg en status på framgangen din, men mentoring det tror
              jeg du kan se langt etter. Det må sies at det er heller ikke noe
              man forventer når man begynner på skolen eller går en bootcamp,
              eller noe de i det heletatt reklamerer med. Det dreier seg om å få
              ny kunnskap inn i hodet og ut gjennom fingerne (og inn i
              tastaturet), som tilslutt blir til karrakterer du kan vise
              potensielle arbeidsgivere når du er ferdig. Man har ofte én lærer
              fordelt på et titalls studenter. Det blir med andre ord lite tid
              til hver enkelt elev, selvom du kanskje har betalt nok til å
              egentlig burde kunne få mye{" "}
              <span style={{ fontWeight: 700 }}>personlig oppfølging</span>. Og
              du kommer mest sannsynlig ikke en gang snakke med en utvikler før
              du er i jobb som junior, og du får din første faktiske mentor som
              viser deg hvordan arbeidslivet fungerer. Jeg føler at det å snakke
              med en utvikler når du selv vil bli det er noe av det første som
              burde skje, men det er dessverre ikke i læreplanen.
            </Text>
          </Flex>
          <Flex my={[2]}>
            <Text>
              Coaching og mentoring fra utviklere er ikke i pensum, fordi det er
              for dyrt og tidkrevende. Det er rett og slett ikke lønnsomt for en
              stor instutisjon som feks et universitet å sette igang, selvom det
              kanskje hadde økt karaktersnittet betraktelig høyere. Dette
              programmet er ikke en del av en stor institusjon, og coaching er i
              aller høyeste grad en del av pensum. Det er et mentorprogram som
              egentlig bare finnes som uformelle rutiner på en arbeidsplass der
              seniorer lærer opp de nye utviklerne som kommer rett fra
              skolebenken. Dette mentorprogrammet vil{" "}
              <span style={{ fontWeight: 700 }}>
                gjøre det tilgjengelig for alle
              </span>
              .
              <span style={{ fontStyle: "italic" }}>
                {" "}
                Jeg tror at de som har behov for en mentor og får tilgang til
                det, vil kunne komme seg mye raskere up to speed når det gjelder
                å få en utviklerjobb, enn om de hadde gått formell utdannelse.
              </span>{" "}
              Hvorfor tror jeg det spør du? Jo, fordi jeg var der selv. Jeg gikk
              en <span style={{ fontWeight: 700 }}>
                3 årig bachelor i IT
              </span>{" "}
              og etterpå en bootcamp jeg absolutt ikke var forberedt på, og jeg
              var i survival mode konstant. Jeg hadde lært mye nyttig, men
              kjente uavbrudt på imposter syndromet som kontinuerlig fortalte
              meg at jeg hadde ikke noe å gjøre der.
            </Text>
          </Flex>
          <Flex my={[2]}>
            <Text>
              {" "}
              En mentor kan ta tak i det, og hjelpe deg gjennom det. Jeg hadde
              et pensum jeg skulle gjennom og oppgaver jeg måtte gjøre. Jeg
              måtte støtte meg på klassekammeratene mine, og det hindret meg fra
              å finne ut ting på egenhånd fordi jeg konstant var redd for å
              havne bakpå med oppgavene og falle av. Men jeg var heldig nok til
              å ha en nær venn som var utvikler med noen år erfaring. Han tok
              seg tid til å snakke med meg og hjelpe meg med utfordringene jeg
              hadde, og det fikk meg på riktig kurs.
              <span style={{ fontWeight: 700 }}>
                {" "}
                Hadde det ikke vært for det så hadde jeg nok ikke vært utvikler
                i dag
              </span>
              . Dessverre så er det ikke mange som har tilgang til gode venner
              som tilfeldigvis er utviklere. Det vil jeg gjøre noe med.
            </Text>
          </Flex>
          <Flex my={[2]}>
            <Text>
              Jeg har selv erfart hva en mentor kan bistå med når ting blir
              tungt og man vil egentlig bare gi opp. Med en mentor som er like
              interessert i å se deg lykkes som deg selv så blir dine
              utfordringer mentorens utfordringer. Og med erfaring så kan en
              mentor gi deg råd som du kanskje ville brukt flere år på å lære.
              På denne måten kan du kjøpe feil som har blitt til erfaring slik
              at du slipper å bruke tid på å gjøre de samme feilene selv, og kan
              gå rett til svaret med en gang.
            </Text>
          </Flex>
          <Flex my={[2]}>
            <Text>
              En annen viktig brikke i det å bli utvikler som du ikke lærer på
              universitetet, fagskole eller bootcamps er{" "}
              <span style={{ fontWeight: 700 }}>
                hvordan man søker på en utviklerjobb
              </span>{" "}
              . Dette er informasjon du tilegner deg gjennom prøving og feiling
              ved å gjøre det selv. Rekrutteringsprosessen for utviklere er i
              andre ord ganske godt skjult for de fleste som ikke har vært
              gjennom det før. Dette vil jeg gjøre noe med å få det fram i lyset
              slik at du kan være best mulig forberedt og få jobben du vil ha.
              Dette inkluderer CV, søknad, vanlig intervjutrening og teknisk
              intervju.
            </Text>
          </Flex>
          <Flex my={[2]}>
            <Text>
              Til slutt er skolen som regel konservativ i forhold til hva man
              lærer som vil si at du lærer det de alltid har lært bort, og du
              lærer skjeldent hvordan å lære effektivt. Mye teori med innspill
              av praktiske oppgaver, innleveringer og eksamener som er de samme
              hvert år skal gi deg et fundament å bygge videre på. Universiteter
              og fagskoler har ofte en tidsforsinkelse i forhold til hva som
              kreves og brukes i arbeidslivet på flere år. Man kan rett å slett
              ikke lære det nyeste på skolen, for det lærer man i arbeislivet. I
              dette programmet får du muligheten til å lære React og Java{" "}
              <span style={{ fontWeight: 700 }}>
                slik det brukes i arbeidslivet
              </span>{" "}
              på en praktisk måte som gjør det morsomt å lære fordi du eier dine
              egen løsninger. Det er ikke bare innleveringer som ikke blir til
              noe annet enn en del av en karakter eller bestått/ikke bestått. Du
              kan bruke dem til å vise kompetansen din når det gjelder.
            </Text>
          </Flex>
          <Flex my={[2]}>
            <Text fontWeight={["700"]} fontSize={["lg", "3xl"]} mt={12}>
              Hvor mye koster det?
            </Text>
          </Flex>
          <Flex my={[2]}>
            <Text>
              Normal pris på programmet er{" "}
              <span style={{ fontWeight: 700 }}>63.000 kr</span> inkl mva. men
              fordi dette er et helt nytt program så er det en{" "}
              <span style={{ color: "#31d47a", fontWeight: 700 }}>
                spesiell rabatt for de 10 første
              </span>{" "}
              som blir med. De <span style={{ fontWeight: 700 }}>første 5</span>{" "}
              som blir med i programmet får{" "}
              <span style={{ fontWeight: 700, color: "red " }}>66% </span>
              rabatt! Og de <span style={{ fontWeight: 700 }}>
                neste 5
              </span>{" "}
              etter det får{" "}
              <span style={{ fontWeight: 700, color: "red " }}>55% </span>
              rabatt! Jeg har bare kapasitet til{" "}
              <span style={{ fontWeight: 700, textDecoration: "underline" }}>
                10 studenter
              </span>{" "}
              om gangen, så vær rask hvis du vil få det til rabattert pris og
              slippe å havne på venteliste!
            </Text>
          </Flex>
          <Flex my={[2]}>
            <img src={promotionImage} alt="promotionImage" />
          </Flex>

          <Flex my={[2]}>
            <Text fontWeight={["700"]} fontSize={["lg", "3xl"]} mt={12}>
              Hva om jeg føler at jeg ikke får noe ut av det og vil avslutte
              mentorprogrammet før 5 måneder er ferdig? Får jeg pengene tilbake?
            </Text>
          </Flex>
          <Flex my={[2]}>
            <Text>
              I tillegg til standard 14 dager angrefrist på kjøpet så kan du når
              som helst be om pengene tilbake den første måneden uten å begrunne
              hvorfor. Programmet vil da avsluttes med en gang og du vil bli
              refundert beløpet du har betalt i sin helhet til kontoen
              betalingen i utgangspunktet kom fra. Ønsker du å avslutte etter en
              måned så vil du bli refundert totalbeløpet minus kostnaden for
              antallet uker du har vært gjennom i programmet. EKSEMPEL: Du har
              vært i programmet i 4 uker og betalt 63.000kr. Etter den andre
              måneden fra du begynte (uke 8) så vil du få 50.400kr tilbake. Slik
              regnes det ut: 63.000kr / 5 = 12.600kr (kostnad per måned).
              12.600kr / 4 = 3.150kr (kostnad per uke). 3.150 kr * 4 (antall
              uker over prøveperioden) = 12.600kr (kostnad for antall uker
              utover prøveperioden). 63.000kr - 12.600kr = 50.400kr refundert
              til deg.
            </Text>
          </Flex>
          <Flex my={[2]}>
            <Text>
              Dette er for å sikre kompensasjon for arbeidet som er lagt ned
              (forberedelse, oppfølging og tilrettelegging) for hver uke. Og bli
              coachet gjennom et intensivt program krever mye og er ikke for
              alle, og det er helt greit å finne ut av det underveis. Gi meg
              bare beskjed så fort så mulig hvis du ikke er fornøyd og ønsker
              pengene tilbake.
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
