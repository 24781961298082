import React from "react";
import ClipboardButton from "./ClipboardButton";

import PropTypes from "prop-types";
import { Flex } from "@chakra-ui/react";

function ContactContainer(props) {
  return (
    <Flex justifyContent="center">
      <ClipboardButton />
    </Flex>
  );
}

export default ContactContainer;
