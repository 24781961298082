import { Box, Button, Flex, Link } from "@chakra-ui/react";
import React from "react";
import "../App.css";
import SocialMediaIcons from "./SocialMediaIcons";
import icon from "../assets/bliutviklerIconWithFrame.png";

function Navbar() {
  return (
    <Flex
      justify={["space-between"]}
      alignItems="center"
      h="80px"
      fontSize="9px"
      fontWeight="bold"
      color="black"
      m="0 10px"
      backgroundColor="#fff"
    >
      <Flex>
        <Link href="/">
          <img src={icon} minwith="70px" className={"iconStyle"} alt="icon" />
        </Link>
      </Flex>
      <Flex fontSize={16}>
        <SocialMediaIcons />
      </Flex>
    </Flex>
  );
}

export default Navbar;
