import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";

function SecondPitch() {
  return (
    <Flex id="about" justify="center" pt={["100px", "180px"]} pb="40px">
      <Box>
        <Flex
          flexDirection="column"
          justify="center"
          w={["auto", 400, 599, 900]}
          mx="5px"
        >
          <Text
            fontSize={["xl", "3xl", "5xl"]}
            fontWeight="bold"
            as="b"
            color="#0B3558"
            lineHeight="1.2"
          >
            Å bli utvikler har aldri vært enklere. Du trenger ikke formell
            utdannelse så lenge du har kunnskapen.
          </Text>
          <Text
            fontSize={["xl", "3xl", "5xl"]}
            fontWeight="bold"
            as="b"
            color="#0066cc"
          >
            Skaff deg en mentor som hjelper deg fra scratch til jobb!
          </Text>
        </Flex>
      </Box>
    </Flex>
  );
}

export default SecondPitch;
