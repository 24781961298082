import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";

function PitchContainer() {
  const introTextContainer = (
    <Text fontWeight="bold" maxW={"600px"} mr={3}>
      Jeg heter Arne og har over 4 års erfaring som fullstack utvikler. I mitt
      mentorprogram får du{" "}
      <span style={{ fontWeight: 800, color: "#bc123a" }}>kompetansen</span> en
      juniorjobb i virkeligheten krever, tett oppfølging{" "}
      <span style={{ fontWeight: 800, color: "#12b4bc" }}>over 5 måneder</span>{" "}
      ,<span style={{ fontStyle: "italic" }}> og</span> du lærer hvordan du
      søker den{" "}
      <span style={{ fontWeight: 800, color: "#ae12bc" }}>
        {" "}
        første junior jobben
      </span>
      .
    </Text>
  );

  const reasonsToBeContainer = (
    <Flex mt={4}>
      <Box
        px={["10px", "20px"]}
        pb="20px"
        maxW={["500px"]}
        bg="#0066cc"
        borderWidth="1px"
        borderRadius="lg"
        opacity="0.8"
        mx={["20px", 0, 0]}
        h={"auto"}
      >
        <Flex>
          <Text
            color="#fff"
            fontSize={["24px", "32px"]}
            mb={"20px"}
            fontWeight={600}
          >
            Hvorfor bli utvikler i 2023?
          </Text>
        </Flex>
        <ul>
          <Text color="#fff" fontSize={["14px", "18px"]}>
            <b>-</b> Startlønn på 500 000 NOK som junior
          </Text>
          <Text color="#fff" fontSize={["14px", "18px"]} mt={2}>
            <b>-</b> Rask lønnsvekst de første 5 årene fram til ca{" "}
            <b>700 000 </b>NOK (mer som konsulent, opp til 1 000 000 i løpet av
            5 år)
          </Text>
          <Text color="#fff" fontSize={["14px", "18px"]} mt={2}>
            <b>-</b> Muligheter for å tjene opptil <b>2 000 000 </b>NOK som
            selvstendig næringsdrivende konsulent som fakturerer gjennom eget
            firma.
          </Text>
          <Text color="#fff" fontSize={["14px", "18px"]} mt={2}>
            <b>-</b> <b>Ikke nødvendig med formell utdanning</b> så lenge du kan
            gjøre jobben på et profesjonelt nivå.
          </Text>
          <Text color="#fff" fontSize={["14px", "18px"]} mt={2}>
            <b>-</b> Kan <b>jobbe hjemmefra</b> i noen tilfeller hver eneste dag
          </Text>
          <Text color="#fff" fontSize={["14px", "18px"]} mt={2}>
            <b>-</b> Et yrke der man er opptatt av kontinuerlig faglig utvikling
            og holde seg konkurransedyktig, og det er vanlig å bytte jobb ofte.
          </Text>
          <Text color="#fff" fontSize={["14px", "18px"]} mt={2}>
            <b>-</b> Ikke så mange vet at du kan lære deg alt du trenger for å
            bli utvikler på <b>3-6 måneder</b> hvis du virkelig går inn for det.
          </Text>
          <Text color="#fff" fontSize={["14px", "18px"]} mt={2}>
            <b>-</b> Du vil aldri ha problemer med å få jobb igjen.
          </Text>
          <Text color="#fff" fontSize={["14px", "18px"]} mt={2}>
            <b>-</b> Det som tilbys her er basically noe som ikke finnes enda i
            det norske markedet. Bytt jobb og bli utvikler, og få en rikere
            hverdag i form av <b>mer penger, frihet og trivsel!</b>
          </Text>
        </ul>
      </Box>
    </Flex>
  );

  const aboutMentorProgramContainer = (
    <Flex
      flexDir={"column"}
      mt={"10rem"}
      w={"100%"}
      maxW={"600px"}
      justify={"center"}
    >
      <Text fontSize={["2xl", "4xl"]} mb={4} fontWeight={500}>
        Litt om mentorprogrammet:
      </Text>
      <ul>
        <Text color="#0B3558" fontSize={["xl", "2xl"]} mt={5}>
          <b>-</b> Ukentlig 1 til 1 oppfølging og coaching over Zoom
        </Text>
        <Text color="#0B3558" fontSize={["xl", "2xl"]} mt={5}>
          <b>-</b> Intensiv læreplan med detaljerte oppgaver som gir deg
          praktisk og virkelighetsnær erfaring med koding
        </Text>
        <Text color="#0B3558" fontSize={["xl", "2xl"]} mt={5}>
          <b>-</b> Bli kompetent nok til å ta juniorjobber innen React eller
          Java
        </Text>
        <Text color="#0B3558" fontSize={["xl", "2xl"]} mt={5}>
          <b>-</b> Lær et framework for CV og søknader som alltid får deg i topp
          20% av Ja-bunken
        </Text>
        <Text color="#0B3558" fontSize={["xl", "2xl"]} mt={5}>
          <b>-</b> Teknisk intervjutrening og vanlig intervjutrening sikrer at
          du er forberedt på krevende rekrutteringsprosesser
        </Text>
        <Text color="#0B3558" fontSize={["xl", "2xl"]} mt={5}>
          <b>-</b> Vit på forhånd hva en utviklerjobb består i, og hvordan du
          kan sørge for at du blir en uerstattelig del av teamet ditt
        </Text>
      </ul>
    </Flex>
  );

  return (
    <Flex id="about" pt={["40px", "80px"]} borderRadius="lg" justify={"center"}>
      <Box borderRadius="lg" m="5px" w={["auto"]} mx={["20px", 0]}>
        <Flex
          fontSize={["3xl", "5xl"]}
          color="#0B3558"
          flexDir={["column", "row"]}
          wrap={"wrap"}
          justify="center"
        >
          {introTextContainer}
          {reasonsToBeContainer}
        </Flex>

        {aboutMentorProgramContainer}
      </Box>
    </Flex>
  );
}

export default PitchContainer;
