import React from "react";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import profileImage from "../assets/profilbilde_landing_page.png";

function Hero({ handleShowMoreMentorInfoClick }) {
  return (
    <Flex id="about" justify="center" pt={["50px", "80px"]}>
      <Box borderRadius="lg" h="auto">
        <Flex justify="center" flexDir={["column", "row"]} flexWrap="wrap">
          <Flex>
            <Box
              borderRadius="lg"
              m="5px"
              w={["auto", 400, 550]}
              mx={["10px", 0]}
            >
              <Text
                fontSize={["2rem", "2rem", "3.8rem"]}
                fontWeight="bold"
                as="b"
                color="#fff"
              >
                Jeg hjelper folk bli utviklere gjennom mitt 5 måneders
                mentorprogram.
              </Text>
              <Button
                onClick={handleShowMoreMentorInfoClick}
                colorScheme="whiteAlpha"
                variant="solid"
                size="lg"
              >
                Finn ut mer
              </Button>
            </Box>
          </Flex>
          <Flex>
            <Box
              p="5px"
              h={["auto"]}
              bg="#fff"
              borderWidth="1px"
              borderRadius="lg"
              mx="auto"
            >
              <img
                src={profileImage}
                alt="profile-image"
                className={"flexProfileImage"}
              />
            </Box>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
}

export default Hero;
